<template>
  <div class="kpi-custom-data-popup">
    <b-overlay
      :show="overlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row class="ml-0 mr-0">
        <b-col class="p-1">
          <div class="pb-1">Value:</div>
          <b-form-input
            class="border-bottom rounded-0 bg-light p-2"
            v-model="value"
            type="number"
            min="0"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="ml-0 mr-0">
        <b-col class="p-1">
          <div class="pb-1">Description:</div>
          <b-form-textarea
            id="textarea"
            v-model="description"
            rows="5"
            max-rows="5"
            class="border-bottom rounded-0 bg-light p-2"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <div class="actions pt-2 m-1" style="display: flex; justify-content: end;">
        <b-button variant="outline-primary" :class="[!value ? 'disabled' : '']" @click="save">Save</b-button>
        <b-button variant="outline-primary outline-primary-delete" :class="[!value ? 'disabled' : '', 'ml-2']" @click="deleteValue">Delete</b-button>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      value: null,
      description: null,
      overlay: false
    }
  },
  computed: {
    selectedPlatform: {
      get() {
        return this.getSelectedPlatform()
      }
    }
  },
  mounted() {
    if (this.$attrs.customKpi && Object.keys(this.$attrs.customKpi).length > 0) {
      this.value = this.$attrs.customKpi.value
      this.description = this.$attrs.customKpi.description
    }
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform'
    ]),
    async save() {
      this.overlay = true
      const changes = {}
      if (!changes[this.$attrs.rowKey]) {
        changes[this.$attrs.rowKey] = {}
      }
      if (!changes[this.$attrs.rowKey][this.$attrs.column]) {
        changes[this.$attrs.rowKey][this.$attrs.column] = {}
      }
      changes[this.$attrs.rowKey][this.$attrs.column] = {
        value: this.value,
        description: this.description
      }
      const data = {
        event: 'update_kpi_custom',
        field: {
          platform: this.selectedPlatform._id,
          changes
        }
      }
      await MtApi.updatePlatform(this.selectedPlatform._id, data)
      this.overlay = false
      this.$modal.hideAll()
      this.$attrs.gridApi.refreshServerSide({ purge: false })
    },
    async deleteValue() {
      this.overlay = true
      const data = {
        event: 'delete_kpi_custom',
        field: {
          platform: this.selectedPlatform._id,
          rowKey: this.$attrs.rowKey,
          column: this.$attrs.column
        }
      }
      await MtApi.updatePlatform(this.selectedPlatform._id, data)
      this.overlay = false
      this.$modal.hideAll()
      this.$attrs.gridApi.refreshServerSide({ purge: false })
    }
  }
}
</script>
<style lang="scss" scoped>
.kpi-custom-data-popup {
  margin: 20px;
}
.outline-primary-delete {
  color: red;
  border-color: red;
  &:hover {
    background-color: red;
    color: white;
  }
}
</style>
